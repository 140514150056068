@import "_mixins";
$orange: #eb6d17;
$orange-dark: #ce5300;

a {
  text-decoration: none;
  color: #fbdc05;
}

a img {
  border: 0;
}

#main a {
  color: #eb6d17;
}

#main a:hover {
  color: #000;
}

body {
  background: #555656;
  font-size: 14px;
  font-weight: lighter;
  line-height: 22px;
}

.button,
.more,
.back {
  background: #eb6d17;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  width: 120px;
  text-align: center;
  color: #fff !important;
  display: block;
  font-weight: 300;
  border-radius: 0px;
}

.button:hover,
.more:hover,
.back:hover {
  background: #ff7400;
}

.more {
  display: none;
}

.message,
.error {
  background: #9ec53e;
  text-align: center;
  color: #fff;
  line-height: 40px;
}

.message i,
.error i {
  margin-right: 5px;
}

.red {
  color: #f07c00;
}

.error {
  background: #f07c00;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

#header_container {
  background: url("/images/header-bg.png");
  padding: 30px 0 100px;
}

.equal {
  display: flex;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 0 auto;
}

.equal>div[class*="col-"] {
  display: flex;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

#header {
  width: 100%;
  margin: auto;
  max-width: 1100px;
}

#header #logo {
  max-width: 450px;
  margin: 0 auto 30px;
  display: block;
  height: 49px;
  width: 100%;
}

#header #logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#menu-container-1 {
  font-family: "Century Gothic", Arial, Helvetica, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 20px;
  list-style-type: none;
  clear: both;
  height: 22px;
  text-align: center;
}

#menu-container-1 ul {
  margin: 0;
  padding: 0;
}

#menu-container-1 li {
  display: inline-block;
  margin: 0;
  list-style-type: none;
  position: relative;
}

#menu-container-1 a {
  display: block;
  padding: 0 15px;
  border-right: 1px dotted #fff;
  color: #fff;
}

#menu-container-1 li:last-child a {
  border: 0;
}

#menu-container-1 a:hover,
#menu-container-1 li.active a {
  color: #fbdc05;
}

#menu-container-1 ul ul {
  position: absolute;
  top: 22px;
  width: 250px;
  background: #181614;
  display: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-top: 2px solid #fbdc05;
  z-index: 100;
}

#menu-container-1 ul ul li {
  display: block;
}

#menu-container-1 ul li ul {
  display: none;
}

#menu-container-1 ul li.activem ul {
  display: block;
}

@media (min-width: 768px) {
  #menu-container-1 ul li:hover ul {
    display: block;
    position: absolute;
    top: 21px;
  }
}

#menu-container-1>ul>li>ul>li>a {
  color: #fff !important;
  font-weight: 300;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 27px;
  text-transform: uppercase;
  padding: 5px 15px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: left;
  border-right: 0;
}

#menu-container-1>ul>li>ul>li>a:hover {
  color: #fbdc05 !important;
}

#slider_content {
  margin: auto;
  margin-top: -80px;
  margin-bottom: 20px;
  width: 100%;
}

.slidesContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  background: url("/images/header-bg.png");
  padding: 10px;
  height: 45px;
  width: 100%;
  display: block;
  -webkit-box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
}

#slider:after {
  clear: both;
  content: "";
  display: block;
}

#main>.container {
  width: auto;

  @include respond-below(xs) {
    padding: 0;
  }

  .row {
    @include respond-below(xs) {
      margin: 0;
    }

    .col-xs-12 {
      @include respond-below(xs) {
        padding: 0;
      }

      ul {
        @include respond-below(xs) {
          padding: 0 10px;
        }
      }
    }
  }
}

.controls .prevBtn {
  display: block !important;
  height: 28px;
  width: 28px;
  background: url("/images/slider-buttons.png") no-repeat;
  float: left;
  cursor: pointer;
  text-indent: -3000px;
  opacity: 1 !important;
}

.controls .prevBtn:hover {
  background: url("/images/slider-buttons.png") no-repeat left bottom;
}

.controls .nextBtn {
  display: block;
  height: 28px;
  width: 28px;
  background: url("/images/slider-buttons.png") no-repeat right bottom;
  float: right;
  cursor: pointer;
  text-indent: -3000px;
  opacity: 1 !important;
}

.controls .nextBtn:hover {
  background: url("/images/slider-buttons.png") no-repeat right top;
}

#promo_container {
  background: #555555;
  margin: 50px auto 0;
  padding: 40px 0;
}

#promo {
  width: 1100px;
  margin: auto;
}

#promotion {
  border-collapse: collapse;
  color: #888888;
}

#promotion thead td {
  color: #ffffff;
  font-size: 19px;
  line-height: 30px;
  text-transform: uppercase;
  padding-bottom: 20px;
}

#promotion thead td a {
  color: #fff;
}

#promotion thead td a:hover {
  text-decoration: none;
}

#promotion td {
  padding: 0 20px 0;
}

#promotion a {
  display: block;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #7d7d7d;
  width: 150px;
  margin: 20px auto 0;
}

#promotion a:hover {
  text-decoration: underline;
}

#footer_container {
  padding: 20px 0;
  background: #fff;
  font-size: 12px;
}

#footer {
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: #949494;
  width: 100%;
}

#footer a {
  color: #000;
  font-weight: bold;
}

#cookie_information {
  position: static;
  padding: 3px 0;
  background: #3a3a3a;
  color: #9d9c9c;
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
}

#cookie_information .row {
  margin: 0;
  padding: 0;
}

#main {
  background: #fff;
  box-sizing: border-box;
  margin: -80px auto 10px;
}

#main:not(.container) {
  width: 100%;
  max-width: 1100px;
  padding: 30px 20px;
}

#main.container {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
}

.article {
  clear: both;

  .text {
    h4 {
      border-bottom: 0;
      max-width: none;
    }
  }
}

.article .text h3 {
  font-size: 20px;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 600;
}

.article .text h2 {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.site-title {
  text-align: right;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding-top: 0;
  margin-top: 0;
}

.title-con {
  text-align: right;
  margin-bottom: 30px;
  border-bottom: 1px solid #fbdc05;
  padding-bottom: 10px;
}

.center {
  /*  text-align: center;*/
}

hr {
  height: 0;
  margin: 5px auto 20px;
  border: 0;
  border-bottom: 1px solid #c8c8c8;
  display: block;
  width: 100%;
}

.contact-map {
  width: 100%;
  height: 400px;
  margin: 10px 0 30px;
  border: 1px solid #c8c8c8;
}

.address {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  text-align: center;
}

.address:last-child {
  border: none;
}

.address a {
  font-weight: bold;
  color: #000;
}

#main .address:nth-child(4n + 1) {
  clear: both;
}

h4 {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 5px;
  max-width: 600px;
  margin-bottom: 10px;
  font-size: 16px;
}

.article-title a {
  color: #000;
}

.article-title a:hover {
  text-decoration: underline;
}

.article-entry {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &.new-car {
    border: 0;
    padding: 0;
    margin: 0;

    .img {
      height: 200px;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: 0;
      }
    }

    .entry {
      position: static;
      top: 0;
      border: 1px solid #ccc;
      border-top: 0;
      padding: 15px;

      h3 {
        margin: 0;
        padding: 0;
        position: static;
        top: 0;
        text-align: center;
      }

      h4 {
        font-size: 14px;
        border-bottom: 0;
      }

      .btn {
        @include border-radius(2px);
        margin: auto;
        display: block;
        max-width: 150px;
      }
    }
  }
}

.article-entry img {
  border: 1px solid #c8c8c8;
}

.article-entry .article-title {
  margin-left: 171px;
  position: relative;
  top: 20px;
}

.article-entry .entry {
  position: relative;
  top: 20px;
}

.article-entry .entry img {
  position: relative;
  top: -20px;
}

.article table h2 {
  margin: 0;
  padding: 0;
  line-height: 22px;
}

.gallery {
  margin: 0;
  clear: both;
}

.gallery a {
  display: block;
  float: left;
  padding: 0 10px 10px 0px;
}

.gallery a div {
  width: 100%;
  height: 100px;
  background: #fff;
}

.gallery a div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pager {
  margin: 20px 0;
}

.pager li {
  list-style-type: none;
  display: inline;
}

.pager li a {
  color: #065aa6;
  margin: 0 10px 0 0;
}

.pager li a.inactive {
  color: #b2b4b3;
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.pager li a:not(.inactive):hover {
  color: #1c1c1c;
}

.pager span {
  margin: 0 10px 0 0;
}

.entry:after,
.gallery:after,
#menu-container-1:after,
#main:after {
  content: "";
  display: block;
  clear: both;
}

#menu-left {
  @include respond-below(sm) {
    display: none;
  }

  float: left;
  width: 100%;
  padding-right: 10px;
  clear: both;
}

#menu-left .title {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 16px;
}

#menu-left div ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

#menu-left ul li {
  border-bottom: 1px solid #eaeaea;
  padding: 3px 0;
}

#menu-left a {
  color: #000;
}

#menu-left ul li a:hover,
#menu-left ul li.active>a {
  color: #eb6d17;
}

#main-container {
  float: right;
  width: 100%;
}

#facebook {
  margin-top: -157px !important;
  z-index: 200 !important;
}

.navbar-header>.navbar-toggle {
  background: transparent;
  display: block;
  margin: auto;
  float: none;
}

.navbar-header>.navbar-toggle .icon-bar {
  background: #fff;
  width: 33px;
}

.navbar-header {
  display: none;
}

#menu-container-1 ul li a i {
  margin-left: 5px;
}

#menu-container-1 ul li a {
  text-decoration: none;
}

.address h3 {
  font-family: "Century Gothic", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 22px;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.address p {
  font-size: 14px;
  font-weight: lighter;
  line-height: 22px;
  margin: 0 0 7px;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto !important;
  border: 0;
  margin-bottom: 0;
}

.menu-left-1,
.menu-left-2,
.menu-left-3,
.menu-left-4,
.menu-left-12,
.menu-left-33 {
  padding: 0;
}

#menu-left-1,
#menu-left-3,
#menu-left-2,
#menu-left-4,
#menu-left-12,
#menu-left-33 {
  width: 100% !important;
  padding: 0px;
}

#menu-container-12>ul>li>a>i {
  display: none;
}

#menu-container-12>ul>li>ul {
  display: none;
  margin: 0;
  padding: 0 0 0 10px;
  background: #f5f1f1;
}

#menu-container-12>ul>li>ul>li {
  border-bottom: 0;
}

#menu-container-12>ul>li.active>ul,
#menu-container-12>ul>li.open>ul {
  display: block;
}

.article-title a {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}

#pp-button {
  margin: 25px auto;
  display: block;
  max-width: 500px;
  border-radius: 0;
  background: #f8db05;
  color: #000 !important;
  border: 0;
}

#pp-button:hover {
  background: #000;
  color: #f8db05 !important;
}

#pp-form {
  display: none;
}

.form-control {
  border-radius: 0 !important;
}

@media (max-width: 1120px) {
  #header_container>.container {
    width: auto;
  }
}

#main table {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

#main table th {
  padding: 3px 3px;
  text-align: center;
  border: 1px solid #d3d3d3;
  background: #eaeaea;
  line-height: 16px;
}

#main-container {
  table {
    thead {
      tr {
        th {
          &:first-child {
            width: 150px;
          }
        }
      }
    }
  }
}

#main table td {
  padding: 3px 5px;
  border: 1px solid #d3d3d3;
}

.article-miniature {
  box-sizing: border-box;
  padding: 0 10px;
  float: left;
  text-align: center;
  margin-bottom: 20px;
}

.article-miniature .article-title {
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 25px;
  background: #eb6d17;
}

.article-miniature .article-title a {
  color: #fff !important;
  font-weight: 300;
  font-size: 14px;
}

.article-miniature>a>div {
  width: 100%;
  height: 200px;
  box-shadow: 0 1px 15px #3d3d3d;
  border: 5px solid #fff;
  box-sizing: border-box;
  margin: auto;
}

.article-miniature>a>div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article .article-title {
  margin-bottom: 20px;
}

.article .entry {
  margin-bottom: 20px;
}

#gallery-75 {
  display: none;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

iframe {
  width: 100%;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#department_container {
  background: #fff;
  padding: 30px 0;
  margin-bottom: 20px;
}

#department_container #department {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  text-align: center;
}

#department_container #department .row div {
  margin: 5px 0px;
}

#department_container #department .site-title {
  border-bottom: 0;
}

#department_container #department a {
  color: #000;
  font-weight: 600;
  padding-bottom: 5px;
}

#department_container #department table td {
  width: 25%;
  vertical-align: top;
}

#department_container #department table tbody tr:last-child td a {
  color: #000;
  font-weight: 600;
}

#title-promo {
  color: #eb6d17;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
}

#title-promo span {
  font-weight: 300;
  font-size: 16px;
  float: right;
}

#title-promo-contact {
  color: #eb6d17;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#title-promo-contact a {
  color: #000;
}

.bx-viewport {
  height: 400px;
}

#department_container .container {
  max-width: 1100px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dedcdc;
}

.langs {
  float: left;
}

.langs img {
  height: 12px;
  margin-right: 5px;
}

.alert {
  margin-bottom: 20px;
  background: #7db616;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

.mt25 {
  margin-top: 25px;
}

.mlr15d {
  margin-left: -15px;
  margin-right: -15px;
}

.bt .langs {
  float: none;
  text-align: center;
  margin-bottom: 10px;
}

.btn {
  min-width: 100px;
  border-radius: 0;
  padding: 4px 12px;
}

.bt #title-promo {
  margin-top: 25px;
}

.btn-success {
  background-color: #5cb85c;
  color: #fff !important;
}

.btn-success:hover {
  background-color: #4d9c4d;
}

table .btn-xs {
  font-size: 11px;
  display: block;
  max-width: 120px;
  margin: 5px auto 0;
}

#text-additional {
  margin: 25px 0 50px;
  font-size: 16px;
  background: #e8e8e8;
  padding: 25px;
  display: none;
}

#text-additional #title {
  font-weight: 600;
  color: #eb6d17;
  margin-bottom: 10px;
}

#text-additional p {
  margin-bottom: 5px;
}

.bx-viewport li img {}

.contact .address p {
  margin-bottom: 0;
  height: 22px;
}

.panel-info {
  border: 0;
  background: #1b1a16;
  border-radius: 0;
  margin-top: 25px;
}

.panel-info>.panel-heading {
  background-color: #fadc05;
  color: #000;
  border: 0;
  border-radius: 0;
  text-align: center;
  font-weight: 600;
}

.panel-info>.panel-body {
  border: 0;
  padding: 10px 25px;
}

.car {
  padding: 5px;
  cursor: pointer;
  color: #fff;
  min-height: 146px;
}

.car.active .car-name {
  background: #fadc05;
  color: #000;
}

.car-img {
  width: 100%;
  height: 100px;
}

.car-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.car-name {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  padding: 4px 0;
}

.car-info input {
  display: none;
}

.car-info .car-info-slider {
  padding: 15px;
  background: #fadc05;
}

.car-info .star {
  color: #fff;
  text-align: center;
}

.car-info .slider {
  left: 50%;
  transform: translateX(-50%);
}

.car-info-p {
  text-align: center;
  margin: 25px 0 10px !important;
  font-weight: 400;
}

.car-lease .contact {
  margin: 15px 0;
  text-align: center;
}

.car-lease .contact .btn {
  background: #fadc05 !important;
  color: #000 !important;
  border-radius: 0;
  border: 0 !important;
  margin-left: 25px;
  margin-top: -5px;
}

.car-lease .contact i {
  color: #fff;
  font-size: 26px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.car-lease .contact a.phone {
  color: #fadc05 !important;
  font-size: 20px;
}

.car-lease .contact a.phone:hover {
  text-decoration: none;
}

.car-lease .contact-img {
  text-align: center;
  margin-top: 15px;
}

.car-lease .contact-img img {
  width: 100%;
  max-width: 200px;
}

.slider-handle {
  background: #000 !important;
}

#slider {
  background: #000 !important;
}

.slider-tick.in-selection,
.slider-selection.tick-slider-selection {
  background: #807d6f !important;
}

#slider .controls {
  background: #807d6f !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  font-size: 13px;
  font-weight: 600;
}

.deposit-slider .slider-tick.in-selection,
.deposit-slider .slider-selection.tick-slider-selection {
  background: #fff !important;
}

.price-title {
  text-align: center;
  font-size: 20px;
  margin-top: 5px !important;
}

.price {
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin-top: 8px !important;
}

.p0 {
  padding: 0;
}

.pp th,
.pp td {
  border: 0 !important;
}

.footer-citis {
  background: url("/images/header-bg.png");
  padding: 15px;
}

.footer-citis .scontainer-title {
  text-align: center;
  color: #dedcdc;
  font-size: 23px;
  margin: 10px auto;
}

.footer-citis ul {
  text-align: center;
  list-style: none;
  padding: 10px 0px;
}

.footer-citis ul li a {
  color: #dedcdc;
  font-size: 13px;
}

#department_container {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

#facebook-icon {
  color: #fff;
  margin-left: 10px;
  font-size: 20px;
}

#main.reser {
  background: #fff;
  padding: 0px;
}

.reser span {
  text-transform: uppercase;
}

.reser .up-text {
  background: #fadc05;
}

.reser .up-text a {
  color: #000;
  text-decoration: highlighttext;
}

.reser #title-promo {
  background: #fff;
  padding: 30px 20px 20px;
  margin: 0px -15px;
}

.reser .reservation-title {
  float: right;
}

.reser .reservation-title .title {
  padding: 30px 0px 20px;
  text-align: right;
}

.reser .reservation-title h3 {
  margin: 0px;
}

.reser-box {
  margin: 0px 5px 15px;

  @include respond-below(xs) {
    margin: 0;
  }
}

.reser .car-select {
  background: #000;
  width: 100%;
  position: relative;
  padding: 15px 25px;

  @include respond-below(xs) {
    padding: 15px 5px;
  }
}

.reser .car-box {
  padding: 5px;
}

.reser .car-title {
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.reser .car-title p {
  padding: 5px;
  font-weight: 400;
  margin: 0px;
}

.reser .car-box.active .car-title {
  background: #fadc05;
  color: #000;
}

.reser .car-box .img-car {
  width: 100%;
  height: 125px;
  cursor: pointer;
}

.reser .car-box .img-car img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reser .car-select-title {
  margin-left: -10px;
}

.reser .car-select-title h3 {
  margin: 0px;
  color: #fadc05;
  font-size: 16px;
  padding: 0px 0px 10px;
}

.reser .up-text {
  padding: 15px;
}

.reser textarea {
  height: 34px;
}

.reser .info textarea {
  height: 70px;
}

.reser .sub {
  background: #fff;
}

.reser .sub-box {
  background: #fff;
  padding: 10px 0;
}

.reser .bot {
  padding-top: 10px;
  background: #fadc05;
}

.social-box {
  text-align: right;
  float: right;
}

.social-box .social {
  display: inline-block;
}

.social-box .social a {
  display: block;
  margin: 0px 5px;
  height: 25px;
  width: 25px;
}

.social-box .social a img {
  color: #000;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reser .uper {
  background: #fadc05;
  margin-top: -20px;
  padding: 20px 0px;
}

.reser .mt25 {
  margin: 0px;
}

.reser .up-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reser .up-text ul i {
  font-size: 12px;
  margin-right: 5px;
}

.noborder td,
.noborder th {
  border: 0 !important;
}

.kangoor-offer-entry {
  margin-bottom: 15px;
}

.kangoor-offer-entry .img {
  height: 200px;
}

.kangoor-offer-entry .img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 1px solid #000;
}

.kangoor-offer-entry .content {
  border: 1px solid #ccc;
  border-top: 0;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 280px;
  padding-bottom: 9px;
  max-height: 300px;
}

.kangoor-offer-entry .content .title a,
.kangoor-offer-details .title {
  font-size: 18px;
  font-weight: 600;
  color: #eb6d17;
}

.kangoor-offer-entry .content .title a {
  font-size: 21px;
}

.kangoor-offer-details .title {
  font-size: 22px;
  margin-bottom: 10px;
}

.kangoor-offer-entry .content .title a:hover {
  text-decoration: none;
}

.kangoor-offer-entry .content .subtitle {
  font-size: 14px;
}

.kangoor-offer-entry .content .price,
.kangoor-offer-details .price {
  padding: 7px 1px 1px 1px;
  background: #f5f5f5;
}

.kangoor-offer-details .price {
  margin: 0 !important;
}

.kangoor-offer-entry .content .price span,
.kangoor-offer-details .price span {
  font-weight: 300;
  font-size: 18px;
}

.kangoor-offer-entry .content .btn {
  margin: 8px auto 10px;
  display: block;
  background: #000000;
  border: 0;
  color: #ffd300 !important;
  text-transform: uppercase;
  padding: 10px;
  max-width: 180px;
  transition: all 0.5s ease;
}

.kangoor-offer-entry .content .btn:hover {
  background: #ccc;
  color: #000 !important;
}

.kangoor-offer-details .row>.col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}

.kangoor-offer-details .price {
  text-align: right;
  padding-right: 15px !important;
  padding-bottom: 10px;
}

.kangoor-offer-entry .price small,
.kangoor-offer-details .price small {
  display: block;
  font-size: 16px;
  font-weight: 300;
}

.kangoor-offer-details #img-big #main-image {
  text-align: center;
  height: 334px;
  cursor: pointer;
  background: #f5f5f5;
}

.kangoor-offer-details #img-big #main-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kangoor-offer-details .mini-images {
  background: #fff;
  padding: 10px 0;
}

.kangoor-offer-details .mini-images a {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: 0 5px 0 0;
}

.kangoor-offer-details .mini-images a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #cfcfcf;
}

.kangoor-offer-details .mini-images a img.active {
  border: 1px solid #ffd300;
}

.kangoor-offer-details .installment {
  background: #f54424;
  padding: 15px 15px 15px 25px;
  color: #fff;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
}

.kangoor-offer-details .installment p {
  font-size: 14px;
  margin-bottom: 5px;
}

.kangoor-offer-details .installment strong {
  font-size: 26px;
  font-weight: 600;
}

.kangoor-offer-details .offer-right {
  padding: 15px;
  background: #000;
}

.kangoor-offer-details .offer-right p,
.kangoor-offer-details .offer-right small {
  color: #fff;
  text-align: center;
  display: block;
}

.kangoor-offer-details .offer-right p {
  font-weight: 600;
  font-size: 16px;
}

.kangoor-offer-details .offer-right .phone {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
}

.kangoor-offer-details .offer-right .phone i {
  color: #fadc05;
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.kangoor-offer-details .offer-right .phone a {
  color: #fff !important;
}

.kangoor-offer-details .offer-right .phone a:hover {
  text-decoration: none;
}

.kangoor-offer-details .offer-right #contact-form {
  margin-top: 25px;
}

.kangoor-offer-details .offer-right #contact-form .col-xs-12 {
  position: relative;
}

.kangoor-offer-details .offer-right #contact-form .col-xs-12 .red {
  position: absolute;
  right: 25px;
  color: #cc0000;
  top: 13px;
  font-size: 26px;
}

.kangoor-offer-details .offer-right .form-control {
  border: 0;
}

.kangoor-offer-details .offer-right .btn-success {
  background: #ffd300;
  border: 0;
  font-weight: 600;
  padding: 7px 25px;
  transition: all 0.5s ease;
}

.kangoor-offer-details .offer-right .btn-success:hover {
  background: #f54424;
}

.kangoor-offer-details .content {
  margin-top: 25px;
}

.kangoor-offer-details .btn-back {
  margin: 15px 0 0 -15px;
}

.kangoor-offer-details #google-map {
  height: 300px;
}

.resp-tabs-list li {
  min-width: 100px;
  text-align: center;
}

#tabs .resp-tab-active {
  border: 1px solid #ffd300 !important;
  border-top: 2px solid #ffd300 !important;
  border-bottom: 0 !important;
}

#tabs .resp-tab-content {
  border: 1px solid #ffd300;
  border-top-color: #ffd300;
  font-size: 17px;
}

table.price-list thead th {
  width: 90px;
}

table.price-list thead th:first-child {
  width: 120px;

  @include respond-below(sm) {
    white-space: wrap !important;
  }
}

table.price-list thead th:nth-child(2) {
  width: 65px;
}

@media (max-width: 767px) {
  .social-box {
    text-align: center;
    padding-bottom: 15px;
    display: none;
  }

  #main-container {
    padding-top: 5px;
  }

  .site-title {
    text-align: center;
  }

  .bt #facebook {
    display: none;
  }

  .navbar-header {
    display: block;
  }

  .bt #menu-container-1 {
    height: auto;
    background: rgba(0, 0, 0, 0.8);
  }

  .bt #menu-container-1 li {
    display: block;
    text-align: center;
  }

  .bt #menu-container-1 li a {
    padding: 5px;
  }

  .bt #menu-container-1 ul ul {
    position: static;
    width: 100%;
  }

  .bt #menu-container-1>ul>li>ul>li>a {
    text-align: center;
  }

  .langs {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  #facebook {
    display: none;
  }

  #menu-container-1 {
    height: 100%;
  }

  .menu-container-1 .level-0 li {
    width: 100%;
  }

  #menu-container-1 li {
    width: 100%;
    margin: 3px;
  }

  #menu-container-1 li ul li {
    margin: 0px;
  }

  #menu-container-1 ul ul {
    position: relative;
    top: 6px;
    margin: auto;
    margin-bottom: 15px;
    width: 100%;
  }

  #menu-container-1 a {
    border: none;
  }

  #menu-container-1>ul>li>ul>li>a {
    text-align: center;
  }

  #title-promo {
    text-align: center;
    width: 100%;
    margin-bottom: 10px !important;
    font-size: 16px;
  }

  #title-promo a {
    white-space: nowrap;
  }

  #title-promo span {
    display: block;
    float: none;
  }

  #menu-left-2 {
    width: 50%;
    float: right;
  }

  .menu-1 {
    float: left;
    width: 100%;
  }

  #menu-left-1 {
    width: 100% !important;
  }

  #menu-left ul li {
    text-align: center;
  }

  .visible-xs {
    display: block !important;
    margin: auto !important;
    float: unset !important;
  }

  .article-entry .article-title {
    margin-left: 0px;
    position: relative;
    top: 5px;
  }

  .article-entry .entry img {
    position: relative;
    top: 4px;
  }

  .car-img {
    height: 200px;
  }

  .car-lease .contact .btn {
    display: block;
    margin: 10px 0;
  }

  .panel-body>.row.equal>.col-xs-12:nth-child(2) {
    padding: 0px;
  }

  .title-con {
    margin-bottom: 5px;
  }

  .menu-left-33 {
    display: none;
  }

  #main {
    padding: 10px 0 !important;
  }

  /*  #main > .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  #main > .container > .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  #main > .container > .row > div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  #main > .container > .row > div[class^="col-"] > .row {
    margin-left: -5px;
    margin-right: -5px;
  }*/
  #main .title-con .col-sm-4 {
    display: none;
  }

  #menu-left .menu-left-1 {
    display: none;
  }

  #menu-left .menu-left-4 {
    display: none;
  }

  #header_container {
    padding: 5px 0 85px;
  }

  #header #logo {
    margin-bottom: 0;
  }

  #department_container {
    padding: 10px 0;
  }

  #department_container .container {
    padding-top: 0;
    margin-top: 0;
  }

  .footer-citis ul {
    margin-bottom: 0;
    padding: 0;
  }

  .navbar-header.visible-xs {
    float: right !important;
    margin-top: -37px !important;
  }

  .text-xs-center {
    text-align: center;
  }

  .table-responsive .btn.btn-xs {
    display: none !important;
  }

  #main table th:not(:first-child),
  #main table td:not(:first-child) {
    white-space: nowrap;
  }

  .gallery {
    margin: 10px 0;
  }

  .article .text h3 {
    font-size: 16px;
  }

  .gallery a {
    height: 100px;
  }
}

#price-small-menu {
  margin-bottom: 10px;

  @include respond-above(sm) {
    display: none;
  }

  #title {
    font-weight: 600;
    text-align: center;
  }

  #content {
    @include flexbox();
    @include flex-direction(row);

    a {
      @include flex-grow(1);
      border: 0;
      font-size: 13px;
      display: inline-block;
      text-align: center;
      width: 33.33333%;
      line-height: 18px;
      background: $orange;
      margin: 0 1px;
      padding: 5px;
      color: #fff;
    }
  }
}

.offer-menu {
  text-align: center;
  margin-bottom: 15px;

  .btn {
    text-transform: uppercase;
    width: 230px;
    @include transition;

    &.btn-system {
      background: $orange;
      color: #fff !important;

      &:hover {
        background: $orange-dark;
      }
    }
  }
}

@include respond-below(md) {
  .price-list {

    thead,
    tbody {
      tr {
        th:first-child {
          white-space: normal !important;
        }
      }
    }
  }

  .article {
    .article {
      padding: 0 5px;
    }
  }
}

//! fix
#footer {
  .fb_dialog.fb_dialog_advanced {
    display: none !important;
  }
}

.article {
  h1 {
    font-size: 1.6em;
    font-weight: 600;
  }
}